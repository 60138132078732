import Constants from "src/Constants";
import { gotoPionexApp } from "src/utils";

/**
 * 将对象转换为?a=5&b=7形式
 * @param obj
 * @param firstStr
 * @returns {string|string}
 */
export function obj2StrParams(obj, firstStr = "?") {
    let params = firstStr;

    for (const p in obj) {
        params += p + "=" + obj[p] + "&";
    }
    return params === firstStr ? "" : params.substring(0, params.length - 1);
}

/**
 * 纯函数形式创建订单, 会处理 web 和 app 跳转的情况
 */
export const gotoPionexCreateOrder = ({ share_id, shareCode }: { share_id: string; shareCode: string }) => {
    const lang = new URLSearchParams(window.location.search).get("l") || "zh-TW";
    const mUrl = `https://download.pionex.com/?lang=${lang}`;
    gotoPionexApp({
        url: `${Constants.shareHost}${window.location.pathname}?bot_share_id=${share_id}&referral=${shareCode}`,
        mUrl,
        page: "TRADE.bot.botOrderList",
        bot_share_id: share_id,
        referral: shareCode,
    });
};
