import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { SafeDecimal } from "trade_utils_lib";
import { TradeUtils } from "TradeAPILib";
import CopyButton from "../../components/CopyButton";
import OpenAppLoading from "../../components/OpenAppLoading";
import OrderAnnualized from "../../components/OrderAnnualized";
import OrderVerParamItem from "../../components/OrderVerParamItem";
import ShareHeader from "../../components/ShareHeader";
import ShareUserInfo from "../../components/ShareUserInfo";
import Space from "../../components/Space";
import { H3, RadiusWrapper, Wrapper } from "../../components/StyleComponent";
import Constants from "../../Constants";
import str from "../../i18n/useStr";
import { useMartingaleSignal, useShareIdDataForMartingale as useShareIdData } from "../../use";
import { getInviteCode } from "../../utils/getInviteCode";
import { isMobile } from "../../utils/index";
import { report } from "../../utils/report";
import DIYParam from "./DIYParam";
import SmartDIYParam from "./SmartDIYParam";
import "./style.css";
import { buildPionexLink } from "trade_utils_lib";
import { SymbolDataProvider } from "trade_quotation_lib";

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />;

/**
 * @type 普通
 * @param
 *  最大加仓次数
 *  跌多少加仓
 *  赚多少止盈 => basic done
 *  价格区间 => basic 可选
 *  止盈百分比 => basic 可选
 *  止损百分比 => basic 可选
 *  触发价格 => basic 可选
 *  加仓倍数倍投 (diy 特殊处理)
 */

/**
 * @type 追踪
 * @param
 *  回落多少后卖出
 *  加仓倍投倍数
 *  反弹多少后买入
 *  赚多少止盈 => basic done
 *  价格区间 => basic 可选
 *  止盈百分比 => basic 可选
 *  止损百分比 => basic 可选
 *  触发价格 => basic 可选
 *  最大加仓次数 (diy 特殊处理)
 *  跌多少加仓 => (diy 特殊处理)
 */

const MartingaleShareTemplate = () => {
    const routerParams = useParams();
    const { share_id } = routerParams as any;
    const mobileDevice = isMobile();
    const [showOpenApp, setShowOpenApp] = React.useState(false);
    const [data, loading] = useShareIdData(share_id);

    const [signal] = useMartingaleSignal(data);

    useEffect(() => {
        report("web_visit_share_param", { share_id: share_id });
    }, [share_id]);

    const profitPercent = React.useMemo(() => {
        if (data.orderData && data.orderData.bu_order_data) {
            const profitRateSting = new SafeDecimal(data.orderData.bu_order_data.total_profit)
                .div(new SafeDecimal(data.orderData.bu_order_data.quote_total_investment))
                .mul(100)
                .toDecimalPlaces(2)
                .toString();

            return profitRateSting;
        }
        return "--%";
    }, [data.orderData]);

    const annualized = React.useMemo(() => {
        // todo 触发单
        if (data.orderData) {
            const timespan = new Date().getTime() - data.orderData.create_time;
            // 总利润/投资额/[(当前时间-create_time)/1000/3600/24]*365*100
            return `${Math.max(
                -100,
                Math.min(new SafeDecimal((Number.parseFloat(profitPercent) / timespan) * 31536000000).toDecimalPlaces(2).toNumber(), 99999),
            )}%`;
        }

        return "--%";
    }, [data.orderData, profitPercent]);

    const orderType = React.useMemo(() => {
        if (data.orderData && data.orderData.bu_order_data) {
            if (data.orderData.bu_order_data.sub_order_type === "normal") {
                if (data.orderData.bu_order_data.portfolio[0].price_scale_type === "custom") {
                    return "normal_diy";
                }
                return "normal";
            }

            if (data.orderData.bu_order_data.sub_order_type === "smart_trade") {
                if (data.orderData.bu_order_data.portfolio[0].price_scale_type === "custom") {
                    return "smart_trade_diy";
                }
                return "smart_trade";
            }
        }
    }, [data]);

    const orderQuote = data.orderData ? data.orderData.quote : "";

    const jumpFn = useCallback(() => {
        const inviteCode = getInviteCode();

        if (mobileDevice) {
            const deepLink = buildPionexLink({
                origin: "pionex://link.pionex.com",
                url: "https://download.pionex.com",
                page: "TRADE.bot.botOrderList",
                share_id,
                //TODO referral only accepts undefined
                referral: inviteCode || undefined,
            });
            setShowOpenApp(true);
            window.open(deepLink);

            setTimeout(() => {
                window.location.replace("https://download.pionex.com");
            }, 1500);
        } else {
            /**
             * example url:
             * https://pionex.com/trade/{BTC_USDT}/Bot?shareId={shareId}
             */
            if (data.orderData?.bu_order_data.portfolio.length > 0) {
                const orderBase = data.orderData.bu_order_data.portfolio[0].base;
                const url = `${Constants.mainHost}/trade/${TradeUtils.parseBaseDisplay(orderBase)}_${orderQuote}/Bot?shareId=${share_id}${
                    inviteCode ? `&r=${inviteCode}` : ""
                }`;
                window.open(url);
            } else {
                // 兜底方法
                window.open("https://download.pionex.com");
            }
        }
    }, [data, mobileDevice, orderQuote, share_id]);

    const beforeConfirm = useCallback(() => {
        Modal.confirm({
            title: str("confirm_copy_order_closed_title"),
            content: str("confirm_copy_order_closed_continue"),
            onOk() {
                jumpFn();
            },

            onCancel() {
                console.log("Cancel");
            },
            cancelText: str("button_cancel"),
            okText: str("subscription_success_confirm"),
            okButtonProps: {
                shape: "round",
                style: { backgroundColor: "#ff7028", borderColor: "#ff7028" },
            },
            cancelButtonProps: { shape: "round", className: "cancleBtn" },
        });
    }, [jumpFn]);

    const handlePress = useCallback(() => {
        report("web_click_copy", { share_id: share_id });
        if (data.orderData?.status !== "open") {
            return beforeConfirm();
        }

        jumpFn();
    }, [beforeConfirm, data.orderData?.status, jumpFn, share_id]);

    const renderOptionsParam = React.useMemo(() => {
        if (!data.orderData) {
            return <></>;
        }

        // *  触发价格 => basic 可选
        const condition = data.orderData.bu_order_data.portfolio[0].condition;
        // *  价格区间 => basic 可选
        // top
        const stop_scaling_price = data.orderData.bu_order_data.portfolio[0].stop_scaling_price;
        // bottom
        const stop_scaling_price_bottom = data.orderData.bu_order_data.portfolio[0].stop_scaling_price_bottom;
        const showStopPrice = stop_scaling_price !== "0";
        const stopPriceString = `${new SafeDecimal(stop_scaling_price_bottom).toString()} - ${new SafeDecimal(
            stop_scaling_price,
        ).toString()}`;

        // *  止盈百分比 => basic 可选
        // const stop_profit_percentage =
        //   data.orderData.bu_order_data.stop_profit_percentage;

        // *  止损百分比 总利润
        const stop_loss_percentage = data.orderData.bu_order_data.stop_loss_percentage;

        // * 止损百分比 浮动盈亏
        const unrealized_stop_loss_percentage = data.orderData.bu_order_data.unrealized_stop_loss_percentage;

        return (
            <>
                {/*trade_trigger_price: "触发价格",*/}
                {!!condition && condition !== "0" && <OrderVerParamItem title={str("trade_trigger_price")} value={condition} />}

                {/*"价格区间",*/}
                {!!showStopPrice && <OrderVerParamItem title={str("martingale_price_range")} value={stopPriceString} />}

                {/*止盈百分比*/}
                {/*{!!stop_profit_percentage && <OrderVerParamItem title={str("martingale_take_profit_percentage")} value={`${new SafeDecimal(stop_profit_percentage).mul(100).toString()}%`} />}*/}

                {/*止损百分比(总利润)*/}
                {!!stop_loss_percentage && (
                    <OrderVerParamItem
                        title={str("martingale_stop_loss_percentage")}
                        value={`${new SafeDecimal(stop_loss_percentage).mul(100).toString()}%`}
                    />
                )}

                {/*止损百分比(浮动盈亏)*/}
                {!!unrealized_stop_loss_percentage && (
                    <OrderVerParamItem
                        title={str("unrealized_stop_loss_percentage")}
                        value={`${new SafeDecimal(unrealized_stop_loss_percentage).mul(100).toString()}%`}
                    />
                )}
            </>
        );
    }, [data.orderData]);

    const renderParam = React.useMemo(() => {
        if (!data.orderData) {
            return <></>;
        }

        // *  跌多少加仓
        let price_scale = data.orderData.bu_order_data.portfolio[0].price_scale_param;
        if (data.orderData.bu_order_data.portfolio[0].price_scale_type === "fixed_num") {
            price_scale = `${price_scale} ${orderQuote}`;
        } else {
            price_scale = `${new SafeDecimal(price_scale).mul(100).toString()}%`;
        }
        // *  加仓倍数倍投
        const volume_scale = new SafeDecimal(data.orderData.bu_order_data.portfolio[0].volume_scale).toString();
        // * 反弹多少后买入
        const price_scale_trailing_percentage = new SafeDecimal(
            data.orderData.bu_order_data.portfolio[0].price_scale_trailing_percentage,
        ).toString();
        // * 回落多少后卖出
        const take_profit_trailing_percentage = new SafeDecimal(
            data.orderData.bu_order_data.portfolio[0].take_profit_trailing_percentage,
        ).toString();

        // *  最大加仓次数
        const safety_orders = data.orderData.bu_order_data.portfolio[0].safety_orders;

        // *  赚多少止盈
        const take_profit_radio = data.orderData.bu_order_data.portfolio[0].take_profit_ratio;
        const title = str("trade_signal");
        let value = "";
        const signalType = data.orderData.bu_order_data.portfolio[0].signal_type ?? "immediately";
        if (signalType === "immediately") {
            value = str("martingale_signal_immediately");
        } else if (signalType === "time_lapse") {
            value = str("martingale_signal_time_lapse_with_unit", {
                time: JSON.parse(data.orderData.bu_order_data.portfolio[0].signal_param)["time"],
            });
        } else if (signal) {
            value = (signal?.lang as any).title;
        }
        return (
            <>
                {/*跌多少加仓*/}
                {(orderType === "normal" || orderType === "smart_trade") && (
                    <OrderVerParamItem title={str("martingale_price_scale")} value={price_scale} />
                )}

                {/*反弹多少后买入*/}
                {orderType === "smart_trade" && (
                    <OrderVerParamItem
                        title={str("martingale_price_scale_trailing_percentage")}
                        value={`${new SafeDecimal(price_scale_trailing_percentage).mul(100).toString()}%`}
                    />
                )}

                {/*赚多少止盈*/}
                <OrderVerParamItem
                    title={str("martingale_take_profit_ratio")}
                    value={`${new SafeDecimal(take_profit_radio).mul(100).toString()}%`}
                />

                {/*回落多少后卖出*/}
                {(orderType === "smart_trade" || orderType === "smart_trade_diy") && (
                    <OrderVerParamItem
                        title={str("martingale_take_profit_trailing_percentage")}
                        value={`${new SafeDecimal(take_profit_trailing_percentage).mul(100).toString()}%`}
                    />
                )}
                {!data.orderData.bu_order_data.is_multi ? (
                    <>
                        {/*最大加仓次数*/}
                        <OrderVerParamItem title={str("martingale_safety_orders")} value={safety_orders} />

                        {/*加仓倍投倍数*/}
                        {(orderType === "normal" || orderType === "smart_trade") && (
                            <OrderVerParamItem title={str("martingale_order_volume_scale")} value={volume_scale} />
                        )}
                    </>
                ) : (
                    <></>
                )}
                <OrderVerParamItem title={title} value={value} />
            </>
        );
    }, [data.orderData, orderQuote, orderType, signal]);

    const title = useMemo(() => {
        if (data.customize_name) {
            return data.customize_name;
        }
        if (data.orderData) {
            if (data.orderData.bu_order_data.is_multi) {
                const orderBases = data.orderData.bu_order_data.portfolio.map((v) => {
                    const symbolInfo = SymbolDataProvider.getSymbol(`${v.base}/${orderQuote}`);
                    return symbolInfo?.baseDisplay;
                });
                if (orderBases.length > 3) {
                    const temp = orderBases.splice(0, 3);
                    return str("share_introduce", {
                        symbol: `${temp.join("/")}...`,
                        strategy_bot: str("mtg_multi_order_name"),
                    });
                } else {
                    return str("share_introduce", {
                        symbol: `${orderBases.join("/")}`,
                        strategy_bot: str("mtg_multi_order_name"),
                    });
                }
            } else {
                if (data.orderData.bu_order_data.portfolio.length > 0) {
                    const orderBase = data.orderData.bu_order_data.portfolio[0].base;
                    const symbolInfo = SymbolDataProvider.getSymbol(`${orderBase}/${orderQuote}`);

                    return str("share_introduce", {
                        symbol: `${symbolInfo?.baseDisplay}/${symbolInfo?.quoteDisplay}`,
                        strategy_bot: str("martingale_bot"),
                    });
                }
            }
        }
        return "";
    }, [data.customize_name, data.orderData, orderQuote]);

    const roundCount = useMemo(() => {
        if (data.orderData) {
            if (data.orderData.bu_order_data.is_multi) {
                return data.orderData.bu_order_data.portfolio.reduce((sum, b) => {
                    return sum + b.round_count;
                }, 0);
            } else {
                return data.orderData.bu_order_data.round_count;
            }
        } else {
            return 0;
        }
    }, [data.orderData]);

    if (showOpenApp) {
        return <OpenAppLoading />;
    }

    if (loading || !data.orderData) {
        return (
            <div>
                <ShareHeader />
                <Wrapper style={{ marginTop: "30%" }}>
                    <Spin indicator={antIcon} />
                </Wrapper>
            </div>
        );
    }

    return (
        <div style={!mobileDevice ? { maxWidth: 750, margin: "0 auto" } : {}}>
            <ShareHeader />
            <ShareUserInfo nickname={str("share_nickname", { nickname: data.nickname })} avatar={data.avatar} title={title} />

            <Space size={20} />

            <OrderAnnualized
                profitPercent={`${Number.parseFloat(profitPercent) > 0 ? "+" : ""}${profitPercent}%`}
                details={[
                    ["annualized_return", annualized],
                    ["lasting", data.orderData.create_time],
                    ["grid_transaction", roundCount],
                ]}
            />

            <div
                style={{
                    marginTop: 20,
                    marginBottom: 6,
                    alignItems: "flex-start",
                    display: "flex",
                    paddingLeft: 18,
                }}
            >
                <H3>{str("parameters")}</H3>
            </div>

            {orderType === "normal_diy" && data.orderData.bu_order_data.portfolio[0] && (
                <DIYParam priceScaleParam={data.orderData.bu_order_data.portfolio[0].price_scale_param} />
            )}

            {orderType === "smart_trade_diy" && data.orderData.bu_order_data.portfolio[0] && (
                <SmartDIYParam priceScaleParam={data.orderData.bu_order_data.portfolio[0].price_scale_param} />
            )}

            <Space size={6} />

            <RadiusWrapper style={{ paddingBottom: 14 }}>
                {renderParam}
                {renderOptionsParam}
            </RadiusWrapper>

            {mobileDevice && <Space size={120} />}

            <CopyButton onPress={handlePress} sticky={mobileDevice} />
        </div>
    );
};

export default MartingaleShareTemplate;
