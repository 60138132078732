import { CustomOrderSide } from "trade_futures_lib";
import str from "src/i18n/useStr";

type OrderSideProps = {
    side: CustomOrderSide;
};

export const OrderSide = ({ side }: OrderSideProps) => {
    if (side === "buy") return <div style={{ color: "#00B070" }}>{str("trade_trend_long")}</div>;
    return <div style={{ color: "red" }}>{str("trade_trend_short")}</div>;
};
