import style from "./style.module.css";

interface IUserCard {
    data: {
        avatar?: string;
        title: string;
        subTitle: React.ReactNode;
        info: Array<{
            key?: string | number;
            label: string;
            value: string;
        }>;
    };
}

export default function UserCard(props: IUserCard) {
    const { avatar, title, subTitle, info } = props.data;

    return (
        <div className={style.infoCard}>
            <div className={style.infoCardHeader}>
                {avatar ? <img className={style.kolAvatar} src={avatar} alt="avatar" /> : <div className={style.kolAvatar} />}
                <div>
                    <div className={style.botInfoTitle}>{title}</div>
                    <div className={style.botInfoInner}>{subTitle}</div>
                </div>
            </div>
            <div className={style.infoCardSplit}>
                <div className={style.infoCardSplitLine}></div>
            </div>
            <div className={style.infoCardFooter}>
                {info.map((item, index) => {
                    return (
                        <div className={style.row} key={item.key ?? index}>
                            <div className={style.infoLabel}>{item.label}</div>
                            <div className={style.infoValue}>{item.value}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
