import style from "./style.module.css";

export function CardSubTags({ tags }: { tags: string[] }) {
    if (!tags?.length) return null;
    const brr: React.ReactNode[] = [];
    tags?.forEach((i, index) => {
        if (index !== 0) {
            brr.push(<div className={style.split} key={`${i}_${index}`} />);
        }
        return brr.push(<div key={i}>{i}</div>);
    });

    return <div className={style.subTitleTagsContainer}>{brr}</div>;
}
