import axios from "axios";
import Constants from "src/Constants";
import useSWR from "swr";
import { ShareData, TOrderDetails } from "./types.d";
import { useCallback, useMemo, useState } from "react";
import { gotoPionexCreateOrder } from "./utils";

/**
 * 通过获取分享id获取原订单数据
 */
export const useShareData = ({
    id,
}: {
    /** 分享ID */
    id: string;
}) => {
    return useSWR(
        id,
        (id) =>
            axios
                .get<TOrderDetails>(`${Constants.kolFHost}/trading_bot_order/?unique_id=${id}`)
                .then((res) => res.data)
                .then((res) => {
                    if (res.code === 0) {
                        return res.data;
                    } else {
                        throw res;
                    }
                }),
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // 已返回错误码
                if (error?.response?.data?.code) return;

                // 404, 403 时不重试。
                if (error.status === 404 || error.status === 403) return;

                // 最多重试 3 次。
                if (retryCount >= 3) return;

                // 500ms后重试。
                setTimeout(() => revalidate({ retryCount: retryCount }), 500);
            },
        },
    );
};

/**
 * 分享跟单创建订单操作, 及提交状态
 */
export function useShareCreateOrder({ data, share_id }: { data: ShareData; share_id: string }) {
    const shareCode = data.extra_data?.share_code || data.share_code;
    const [isSubmitting, setIsSubmitting] = useState(false);
    /**
     * 跳转到pionex创建订单
     */
    const gotoPionexCreateOrderCb = useCallback(() => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        setTimeout(() => setIsSubmitting(false), 3000);
        gotoPionexCreateOrder({ share_id, shareCode });
    }, [isSubmitting, shareCode, share_id]);

    return {
        isSubmitting,
        gotoPionexCreateOrder: gotoPionexCreateOrderCb,
    };
}

/**
 * 处理分享的通用参数及信息
 */
export function useShareBaseParams({ data }: { data: ShareData }) {
    const qrUrl = useMemo(() => `${Constants.shareHost}${window.location.pathname}`, []);
    const shareCode = data.extra_data?.share_code || data.share_code;
    const isPrivate = !!data.extra_data?.is_private;

    return {
        qrUrl,
        shareCode,
        isPrivate,
    };
}
