import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import str from "src/i18n/useStr";
import style from "./style.module.css";
import ReactDOM from "react-dom";

interface ModalProps {
    title?: Element;
    content?: any;
    confirmText?: string;
    cancelText?: string;
    visible?: boolean;
    renderFooter?: () => React.ReactNode;
    onConfirm?: () => void;
    onClose?: () => void;
}

export const ModalConfirm = forwardRef(function ModalConfirmInner(props: ModalProps = {}, ref) {
    const [modalVisible, setModalVisible] = useState(() => {
        return props.visible ?? false;
    });
    const paramsRef = useRef<ModalProps | null>(null);

    useImperativeHandle(ref, () => ({
        show: (params: ModalProps) => {
            paramsRef.current = params;
            setModalVisible(true);
        },
        hide: () => {
            setModalVisible(false);
        },
    }));

    const cancelText = paramsRef.current?.cancelText ?? props.cancelText ?? str("modal_cancel_text");
    const confirmText = paramsRef.current?.confirmText ?? props.confirmText ?? str("modal_confirm_text");

    return modalVisible ? (
        <div>
            <div className={`${style.pionexModalMask}`}></div>
            <div className={`${style.pionexModalContaner}`}>
                <div className={style.pionexModalContanerTitle}>
                    {paramsRef.current?.title ?? props.title ?? str("modal_confirm_title")}
                </div>
                <div className={style.pionexModalContanerContent}>{paramsRef.current?.content ?? props.content ?? ""}</div>
                {props.renderFooter ? (
                    props.renderFooter()
                ) : (
                    <div className={style.pionexModalContanerFooter}>
                        <div
                            className={`${style.pionexModalContanerFooterBtn} ${style.pionexModalContanerFooterCancelBtn}`}
                            onClick={() => {
                                setModalVisible(false);
                                const onClose = paramsRef.current?.onClose ?? props.onClose;
                                onClose && onClose();
                            }}
                        >
                            {cancelText}
                        </div>
                        <div
                            className={`${style.pionexModalContanerFooterBtn} ${style.pionexModalContanerFooterOkBtn}`}
                            onClick={() => {
                                const onConfirm = paramsRef.current?.onConfirm ?? props.onConfirm;
                                onConfirm && onConfirm();
                                setModalVisible(false);
                            }}
                        >
                            {confirmText}
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : null;
});

export function confirm(props: ModalProps) {
    const modalRoot = document.querySelector("body")!;
    return ReactDOM.createPortal(<ModalConfirm {...props} />, modalRoot);
}
