import style from "./style.module.css";
import { useMemo } from "react";
import { isMobile } from "src/utils";
import Constants from "src/Constants";
import { DEFAULT_AVATAR } from "src/components/ShareUserInfo";
import str from "src/i18n/useStr";
import UserCard from "src/ShareUIComponentV2/UserCard";
import Tips from "src/ShareUIComponentV2/Tips";
import { ProfitCard } from "src/ShareUIComponentV2/ProfitCard";
import PionexLogo from "src/ShareUIComponentV2/PionexLogo";
import CodeCard from "src/ShareUIComponentV2/CodeCard";
import { useImage } from "src/use";
import { useShareBaseParams } from "../../hooks";
import { ShareData } from "../../types.d";

/**
 * 分享落地页UI组件. 包括用户信息卡片、收益率卡片, 邀请码卡片
 */
export const ShareLandingPage = ({
    data,
    topTips,
    onCreateOrder,
    title,
    subTitle,
    userCardItems,
    profitCardInfo,
    isCreateBtnLoading,
}: {
    data: ShareData;
    /** 顶部tips */
    topTips: { visible: boolean; text?: string };
    title: string;
    subTitle: React.ReactNode;
    /** 用户信息卡片展示的信息列表内容 */
    userCardItems: { key: string; label: string; value: string }[];
    /** 收益率卡片内容 */
    profitCardInfo: {
        /** 大字收益率标题 */
        title: any;
        /** 大字收益率值 */
        profit: string;
        /** 信息列表内容 */
        info: {
            key?: string | number;
            label: any;
            value: string;
        }[];
    };
    /**
     * 点击跟单按钮后的回调函数
     */
    onCreateOrder: () => void;
    /** 跟单按钮是否loading, 如果为loading状态则无法再次点击 */
    isCreateBtnLoading?: boolean;
}) => {
    const mobileDevice = isMobile();

    const { qrUrl, shareCode, isPrivate } = useShareBaseParams({ data });
    const avatar = useImage(`${Constants.avatarLocationOrigin}${data?.avatar}`, `${Constants.avatarLocationOrigin}${DEFAULT_AVATAR}`);

    // 用户卡片展示信息
    const userInfo = useMemo(() => {
        return {
            avatar,
            title: title,
            subTitle,
            info: userCardItems,
        };
    }, [avatar, title, subTitle, userCardItems]);

    // 底部邀请码展示信息
    const codeInfo = useMemo(() => {
        return {
            title: str("futures_grid_v2_share_code", { name: data?.nick_name }),
            code: shareCode,
            followUrl: qrUrl,
        };
    }, [data?.nick_name, qrUrl, shareCode]);

    return (
        <div className={!mobileDevice ? style.normalContent : style.mobileContent}>
            <PionexLogo />
            <Tips text={topTips.text} visible={topTips.visible} />
            <UserCard data={userInfo} />
            <ProfitCard data={profitCardInfo} />
            <div className={`${style.createOrderBtnBase} ${style.createOrderBtn}`} onClick={() => onCreateOrder?.()}>
                {isCreateBtnLoading ? (
                    <img src={require("../../../../images/icon_loading_white.png")} className={style.loadingIcon} alt="loading" />
                ) : null}
                {str("futures_grid_v2_create_order")}
            </div>
            {isPrivate && <div className={style.privateTip}>{str("grid_futures_invited_only_tip", { creator: data?.nick_name })}</div>}
            <CodeCard data={codeInfo} />
        </div>
    );
};
