import { useParams } from "react-router-dom";
import { useShareRatioData } from "../BotShareLanding/useShareRatioData";
import style from "./style.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { $num, addPlus, gotoPionexApp, isMobile } from "src/utils";
import Constants from "src/Constants";
import { DEFAULT_AVATAR } from "src/components/ShareUserInfo";
import str from "src/i18n/useStr";
import { useTickerIndex } from "../FutureGrid/CurrentPrice/useTickerIndex";
import { calculateAnnualized, calculateProfitPercent } from "../FutureGrid/calculation";
import { SafeDecimal, buildPionexLink } from "trade_utils_lib";
import { getLastDesc } from "src/components/OrderAnnualized";
import moment from "moment";
import { Wrapper } from "src/components/StyleComponent";
import { Modal, Spin } from "antd";
// eslint-disable-next-line import/namespace
import { LoadingOutlined } from "@ant-design/icons";
import { SmartRebalanceMode, TradeUtils } from "TradeAPILib";
import UserCard from "src/ShareUIComponentV2/UserCard";
import { ProfitCard } from "src/ShareUIComponentV2/ProfitCard";
import CodeCard from "src/ShareUIComponentV2/CodeCard";
import { useImage, useSymbolPrice } from "src/use";
import { SymbolDataProvider } from "trade_quotation_lib";

const SRView = ({ data, share_id }: { data; share_id: string }) => {
    const { bu_order_data } = data?.order_data?.payload || {};
    const mobileDevice = isMobile();

    // 开单时BTC价格
    const { value: indexPrice } = useSymbolPrice("BTC", "USDT", data?.order_create_time);

    // 当前BTC价格
    const { data: currentPriceResp } = useTickerIndex({
        base: "BTC",
        quote: "USDT",
    });
    const currentPrice = currentPriceResp?.data?.[0]?.price ?? "";

    const exchangeOrder = useMemo(() => {
        return TradeUtils.convertToExchangeOrder({
            ...data?.order_data?.payload,
            bu_order_type: data?.bu_order_type,
            bu_order_id: data?.bu_order_id,
            bu_order_data: {
                ...data?.order_data?.payload?.bu_order_data,
                create_time: data?.order_create_time,
                close_time: data?.order_end_time,
            },
        });
    }, [data]);
    const order = exchangeOrder ? TradeUtils.getInnerOrder(exchangeOrder) : null;

    // 当前收益率
    const currentProfit = useMemo(() => {
        return calculateProfitPercent({
            total_profit: bu_order_data?.total_profit,
            quote_investment: order?.totalInvest,
        });
    }, [order?.totalInvest, bu_order_data?.total_profit]);

    // 年化收益率
    const annualizedProfit = useMemo(() => {
        const n = calculateAnnualized({
            createTime: data?.order_create_time ?? 0,
            percentProfit: currentProfit,
            endTime: data?.order_end_time,
        });
        return `${n}`.replace(/[^0-9.-]/gi, "");
    }, [data?.order_create_time, data?.order_end_time, currentProfit]);

    const qrUrl = useMemo(() => `${Constants.shareHost}${window.location.pathname}`, []);
    const shareCode = data.extra_data?.share_code || data.share_code;
    const isPrivate = !!data.extra_data?.is_private;
    const webUrl = `${Constants.mainHost}/trade/Bot?sr_share_id=${share_id}${shareCode ? `&referral=${shareCode}` : ""}`;

    const [submitting, setSubmittingState] = useState(false);
    const onCreateOrder = useCallback(() => {
        setSubmittingState(true);
        setTimeout(() => setSubmittingState(false), 3000);
        const lang = new URLSearchParams(window.location.search).get("l") || "zh-TW";
        const mUrl = `https://download.pionex.com/?lang=${lang}`;
        gotoPionexApp({
            url: webUrl,
            mUrl,
            page: "TRADE.bot.botOrderList",
            sr_share_id: share_id,
            referral: shareCode,
        });
    }, [shareCode, share_id, webUrl]);

    const coins = useMemo(() => {
        return order?.coins
            ?.filter((coinFilter) => {
                if (coinFilter.isDeleted) return false;
                if (order?.rebalanceTriggerType === SmartRebalanceMode.momentum) {
                    return new SafeDecimal(coinFilter.percent).greaterThanOrEqualTo(0);
                }

                return new SafeDecimal(coinFilter.percent).greaterThan(0);
            })
            .sort((a, b) => b.percent - a.percent);
    }, [order?.coins, order?.rebalanceTriggerType]);

    const info = useMemo(() => {
        const len = coins?.length;
        const coinDisplay = coins
            ?.slice(0, 2)
            .map((i) => SymbolDataProvider.getCoinDisplay(i.baseDisplay))
            .join("/");
        if (len > 2) {
            return str("sr_coins", { coins: coinDisplay, count: len });
        } else {
            return coinDisplay;
        }
    }, [coins]);

    const onCreateOrderProxy = useCallback(() => {
        if (submitting) {
            return;
        }
        if (exchangeOrder?.isRunning) {
            onCreateOrder();
        } else {
            Modal.confirm({
                title: str("confirm_copy_order_closed_title"),
                content: str("confirm_copy_order_closed_continue"),
                onOk() {
                    onCreateOrder();
                },
                cancelText: str("button_cancel"),
                okText: str("subscription_success_confirm"),
                okButtonProps: {
                    style: { backgroundColor: "#ff7028", borderColor: "#ff7028" },
                },
                cancelButtonProps: { className: "cancleBtn" },
            });
        }
    }, [exchangeOrder?.isRunning, submitting, onCreateOrder]);

    // 比较当前收益和年化收益
    const profitCompare = useMemo(() => {
        const annualized = {
            label: str("annualized_return"),
            value: `${addPlus(annualizedProfit)}${$num(annualizedProfit)}%`,
        };
        const current = {
            label: str("futures_grid_v2_total_profit"),
            value: `${addPlus(currentProfit)}${$num(currentProfit)}%`,
        };
        const annualizedBigger = new SafeDecimal(currentProfit).lessThan(annualizedProfit);

        return {
            lager: annualizedBigger ? annualized : current,
            smaller: annualizedBigger ? current : annualized,
        };
    }, [annualizedProfit, currentProfit]);

    const avatar = useImage(`${Constants.avatarLocationOrigin}${data?.avatar}`, `${Constants.avatarLocationOrigin}${DEFAULT_AVATAR}`);

    // 用户卡片展示信息
    const userInfo = {
        avatar,
        title: data?.customize_name ? data.customize_name : str("sr_title", { name: data?.nick_name ?? "" }),
        subTitle: info,
        info: [
            {
                label: str("order_start_market_price"),
                value: `${indexPrice ? $num(indexPrice, new SafeDecimal(indexPrice).dp()) : "--"} USDT`,
            },
            {
                label: str("current_market_price"),
                value: `${currentPrice ? $num(currentPrice, new SafeDecimal(currentPrice).dp()) : "--"} USDT`,
            },
            ...(data.shared_ration && new SafeDecimal(data.shared_ration).greaterThan(0)
                ? [
                      {
                          label: str("grid_position_share_ratio_percent"),
                          value: `${new SafeDecimal(data.shared_ration).mul(100).toString()}%`,
                      },
                  ]
                : []),
        ],
    };

    // 收益率卡片展示信息
    const profitInfo = useMemo(() => {
        return {
            title: profitCompare.lager.label,
            profit: profitCompare.lager.value,
            info: [
                {
                    label: profitCompare.smaller.label,
                    value: profitCompare.smaller.value,
                },
                {
                    label: str("lasting"),
                    value: getLastDesc(data?.order_create_time, data?.order_end_time),
                },
                {
                    label: str("futures_grid_v2_order_create_time"),
                    value: moment(data?.order_create_time).format("YYYY/MM/DD HH:mm"),
                },
            ],
        };
    }, [
        data?.order_create_time,
        data?.order_end_time,
        profitCompare.lager.label,
        profitCompare.lager.value,
        profitCompare.smaller.label,
        profitCompare.smaller.value,
    ]);

    // 底部邀请码展示信息
    const codeInfo = useMemo(() => {
        return {
            title: str("futures_grid_v2_share_code", { name: data?.nick_name }),
            code: shareCode,
            followUrl: qrUrl,
        };
    }, [data?.nick_name, qrUrl, shareCode]);

    return (
        <div className={!mobileDevice ? style.normalContent : style.mobileContent}>
            <div className={style.logoBox}>
                <img className={style.logoIcon} src={require("../../images/logo_white.png")} alt="" />
            </div>
            {!exchangeOrder?.isRunning && (
                <div className={style.orderClosedTipsBox}>
                    <img className={style.orderClosedTipsIcon} src={require("../../images/tips.png")} alt="" />
                    <div className={style.orderClosedTipsText}>{str("grid_futures_order_closed")}</div>
                </div>
            )}
            <UserCard data={userInfo} />
            <ProfitCard data={profitInfo} />
            <div
                className={`${style.createOrderBtnBase} ${exchangeOrder?.isRunning ? style.createOrderBtn : style.createOrderBtnDisable}`}
                onClick={onCreateOrderProxy}
            >
                {submitting ? <img src={require("../../images/icon_loading_white.png")} className={style.loadingIcon} /> : null}
                {str("futures_grid_v2_create_order")}
            </div>
            {isPrivate && <div className={style.privateTip}>{str("grid_futures_invited_only_tip", { creator: data?.nick_name })}</div>}
            <CodeCard data={codeInfo} />
        </div>
    );
};

export const SR = () => {
    const { share_id } = useParams<{ share_id: string }>();
    const { data, isLoading } = useShareRatioData({ id: share_id });
    const mobileDevice = isMobile();

    useEffect(() => {
        document.body.classList.add(style.page);
        document.documentElement.classList.add("theme-dark");
        return () => {
            document.body.classList.remove(style.page);
            document.documentElement.classList.remove("theme-dark");
        };
    }, []);

    if (isLoading || !data) {
        return (
            <Wrapper style={{ marginTop: mobileDevice ? "50%" : "10%" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />} />
            </Wrapper>
        );
    }
    return <SRView data={data} share_id={share_id} />;
};
