import { ExchangeOrderType, OrderShareResp } from "TradeAPILib";
import Constants from "../Constants";
import { langEnum } from "../i18n/types";
import str from "../i18n/useStr";
import { CopyBotShareInfo } from "../use/types";
import { getInviteCode } from "./getInviteCode";
import { NumberFormatter, buildPionexLink } from "trade_utils_lib";

export function rdp(size) {
    return (window.screen.width / 375) * size;
}

export const queryLang = () => {
    const jsSrc = (navigator.language || "en-US").toLowerCase();
    if (jsSrc.indexOf("zh") !== -1) {
        if (jsSrc.indexOf("tw") !== -1) {
            // 繁体
            return langEnum.tw;
        }
        return langEnum.cn;
    } else if (jsSrc.indexOf("ko") !== -1) {
        return langEnum.ko;
    } else if (jsSrc.indexOf("ru") !== -1) {
        return langEnum.ru;
    } else if (jsSrc.indexOf("de") !== -1) {
        return langEnum.de;
    } else if (jsSrc.indexOf("es") !== -1) {
        return langEnum.es;
    }
    return langEnum.us;
};

export function isMobile() {
    return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(
        navigator.userAgent,
    );
}

export function complementDigit(value, n) {
    if (`${value}`.length >= n) {
        return `${value}`;
    }
    return `${Array(n).join("0")}${value}`.slice(-n);
}

// 毫秒
export const getFormatTimeLasting = (timeInterval) => {
    if (timeInterval > 0) {
        let chaTime = timeInterval;
        const day = 1000 * 60 * 60 * 24; // 一天等于毫秒数
        const hour = 1000 * 60 * 60; // 一小时等于毫秒数
        const dayResult = Math.floor(chaTime / day); //

        chaTime = chaTime - dayResult * day; // 减去天的毫秒数。再求小时个数
        const hourResult = Math.floor(chaTime / hour);
        chaTime = chaTime - hourResult * hour; // 减去小时的毫秒数。再求分钟个数
        const minuteResult = Math.floor(chaTime / (1000 * 60));
        const secondResult = Math.floor((chaTime - minuteResult * 1000 * 60) / 1000); // 减去分钟的毫秒数。再求秒的个数
        return {
            day: complementDigit(dayResult, 2),
            hour: complementDigit(hourResult, 2),
            minute: complementDigit(minuteResult, 2),
            second: complementDigit(secondResult, 2),
        };
    } else {
        const defaultZero = complementDigit(0, 2);

        return {
            day: defaultZero,
            hour: defaultZero,
            minute: defaultZero,
            second: defaultZero,
        };
    }
};

export const handleSharePress = (shareOrderData: OrderShareResp | undefined, shareId, onPress) => {
    if (shareOrderData?.bu_order?.close_time) {
        return alert(str("share_order_closed"));
    }

    const mobileDevice = isMobile();
    const inviteCode = getInviteCode();

    const deepLink = buildPionexLink({
        origin: "pionex://link.pionex.com",
        url: "https://download.pionex.com",
        page: "TRADE.bot.botOrderList",
        share_id: shareId,
        //TODO referral only accepts undefined
        referral: inviteCode || undefined,
    });

    if (mobileDevice) {
        onPress();
        window.open(deepLink);

        setTimeout(() => {
            window.location.replace("https://download.pionex.com");
        }, 1500);
    } else if (shareOrderData?.bu_order?.bu_order_type === ExchangeOrderType.SR) {
        window.open(`${Constants.mainHost}/trade/Bot?shareId=${shareId}${inviteCode ? `&referral=${inviteCode}` : ""}`);
    } else {
        window.open("https://download.pionex.com");
    }
};

export const handleGridShareClick = (data, status, shareId, onPress) => {
    if (status !== "open") {
        return alert(str("share_order_closed"));
    }

    const mobileDevice = isMobile();
    const inviteCode = getInviteCode();
    const isEBBot = data?.orderData?.originOrderData.bu_order_data?.cate_type === "eb";

    const deepLink = isEBBot
        ? buildPionexLink({
              origin: "pionex://link.pionex.com",
              url: "https://download.pionex.com",
              page: "EBBotFeedDetail",
              buOrderType: data.orderData.orderType,
              buOrderId: data.orderData.copyFrom,
              referral: inviteCode || undefined,
          })
        : buildPionexLink({
              origin: "pionex://link.pionex.com",
              url: "https://download.pionex.com",
              page: "TRADE.bot.botOrderList",
              share_id: shareId,
              //TODO referral only accepts undefined
              referral: inviteCode || undefined,
          });

    if (mobileDevice) {
        onPress();
        window.open(deepLink);

        setTimeout(() => {
            window.location.replace("https://download.pionex.com");
        }, 1500);
    } else {
        if (data.botType === ExchangeOrderType.gridV4 || data.botType === ExchangeOrderType.gridV5) {
            const inviteCode = getInviteCode();
            window.open(
                `${Constants.mainHost}/trade/${data.orderData.base}_${data.orderData.quote}/Bot?orderType=${
                    data.botType
                }&shareId=${shareId}${inviteCode ? `&referral=${inviteCode}` : ""}`,
            );
        } else {
            window.open("https://download.pionex.com");
        }
    }
};

export const copyTradeHandlePress = (shareInfo: CopyBotShareInfo, shareId, orderId, orderType, onPress) => {
    if (shareInfo.status !== "open") {
        return alert(str("share_order_closed"));
    }

    const mobileDevice = isMobile();
    const inviteCode = getInviteCode();
    const isEBBot = shareInfo?.cate_type === "eb";
    const deepLink = buildPionexLink({
        origin: "pionex://link.pionex.com",
        url: "https://download.pionex.com",
        page: isEBBot ? "EBBotFeedDetail" : "BotFeedDetail",
        buOrderType: orderType,
        buOrderId: orderId,
        referral: inviteCode || undefined,
        ...(orderType === ExchangeOrderType.SR ? { showCopySheet: true } : {}),
    });
    if (mobileDevice) {
        onPress();
        window.open(deepLink);

        setTimeout(() => {
            window.location.replace("https://download.pionex.com");
        }, 1500);
    } else {
        if (
            (orderType === ExchangeOrderType.gridV4 || orderType === ExchangeOrderType.gridV5) &&
            shareInfo.baseList &&
            shareInfo.baseList.length > 0
        ) {
            let url = "";
            if (isEBBot) {
                if (shareId) {
                    url = `${Constants.mainHost}/trade/${shareInfo.baseList[0]}_${shareInfo.investCoin}/Bot?&shareId=${shareId}${
                        inviteCode ? `&r=${inviteCode}` : ""
                    }`;
                } else {
                    url = `${Constants.mainHost}/trade/${shareInfo.baseList[0]}_${
                        shareInfo.investCoin
                    }/Bot?orderType=${orderType}&orderId=${orderId}&cate_type=eb${inviteCode ? `&r=${inviteCode}` : ""}`;
                }
            } else {
                url = `${Constants.mainHost}/trade/${shareInfo.baseList[0]}_${
                    shareInfo.investCoin
                }/Bot?orderType=${orderType}&orderId=${orderId}${inviteCode ? `&r=${inviteCode}` : ""}`;
            }
            window.open(url);
        } else if (orderType === ExchangeOrderType.SR) {
            const url = `${Constants.mainHost}/trade/Bot?orderType=${orderType}&orderId=${orderId}${inviteCode ? `&r=${inviteCode}` : ""}`;
            window.open(url);
        } else {
            window.open("https://download.pionex.com");
        }
    }
};

export const handleFutureGridShareClick = ({
    base,
    quote,
    shareId,
    onPress,
}: {
    base: string;
    quote: string;
    shareId: string;
    onPress: () => void;
}) => {
    const mobileDevice = isMobile();
    const inviteCode = getInviteCode();

    const deepLink = buildPionexLink({
        origin: "pionex://link.pionex.com",
        url: "https://download.pionex.com",
        page: "TRADE.bot.botOrderList",
        share_id: shareId,
        //TODO referral only accepts undefined
        referral: inviteCode || undefined,
    });

    if (mobileDevice) {
        onPress();
        window.open(deepLink);

        setTimeout(() => {
            window.location.replace("https://download.pionex.com");
        }, 1500);
    } else {
        const inviteQuery = inviteCode ? `referral=${inviteCode}` : "";
        window.open(`${Constants.mainHost}/futures/${base}_${quote}/Bot?shareId=${shareId}&${inviteQuery}`);
    }
};

export function addPlus(n: string) {
    return !n.startsWith("-") ? "+" : "";
}

export function $num(n: string | number, precision?: number) {
    return NumberFormatter.format(n, { locale: queryLang(), precision });
}
/** 与新版$num参数格式一致 */
export function $num2(n: string | number, options?: { precision?: number }) {
    return NumberFormatter.format(n, { locale: queryLang(), precision: options?.precision });
}
/**
 * 跳转到派网app
 * @param url pc页面
 * @param mUrl 移动端页面 非必选
 * @param page app目标页面
 * @param other 其他传给app的参数
 */
export const gotoPionexApp = (params: { url: string; mUrl?: string; page: string; [k: string]: any }) => {
    const { url, mUrl, page, ...other } = params;
    const mobileDevice = isMobile();
    const deepLink = buildPionexLink({
        origin: "pionex://link.pionex.com",
        url,
        mUrl,
        page,
        ...other,
    });
    if (mobileDevice) {
        // 跳转app
        window.location.href = deepLink;
        const maxTime = 2000;
        let timeCount = 0;
        let timer = setInterval(() => {
            // 如果页面转到后台了，那么说明跳转成功了。原页面就不跳转到配置的url。
            if (window.document.visibilityState === "hidden") {
                clearInterval(timer);
            }
            // 超过 maxTime 时间，就把原页面转到配置的url;
            if (timeCount < maxTime) {
                timeCount += 50;
            } else {
                clearInterval(timer);
                window.location.href = mUrl || url;
            }
        }, 200);

        setTimeout(() => {
            if (window.document.visibilityState !== "hidden") {
                window.location.href = mUrl || url;
            }
        }, 3000);
    } else {
        window.location.href = url;
    }
};
