import style from "./style.module.css";
import { useCallback, useMemo, useRef } from "react";
import { $num2, addPlus, isMobile } from "src/utils";
import Constants from "src/Constants";
import { DEFAULT_AVATAR } from "src/components/ShareUserInfo";
import str from "src/i18n/useStr";
import { useTickerBatch } from "../FutureGrid/CurrentPrice/useTickerIndex";
import { calculateAnnualized } from "../FutureGrid/calculation";
import { ExchangeTicker, SafeDecimal } from "trade_utils_lib";
import { getLastDesc } from "src/components/OrderAnnualized";
import moment from "moment";
import { ExchangeMTGFuturesOrder, TradeUtils } from "TradeAPILib";
import { useImage, useSymbolPrice } from "src/use";
import { ModalConfirm } from "src/components/Modal/index";
import { report } from "src/utils/report";
import { MTGFuturesShareProcessor } from "trade_lib_wrapper";
import { CardSubTags } from "../../components/CardSubTags/index";
import { ShareData } from "./types.d";
import { ShareLandingPage } from "./Components/ShareLandingPage/index";
import { useShareCreateOrder } from "./hooks";

export const MTGFuturesView = ({ data, share_id }: { data: ShareData; share_id: string }) => {
    const mobileDevice = isMobile();
    const modalRef = useRef<any>(null);

    const quote = data?.order_data?.payload?.quote ?? "USDT";
    const base = data?.order_data?.payload?.bu_order_data?.portfolio?.[0]?.base ?? "";

    const tickerCoins = useMemo(() => {
        return [{ base: base, quote }];
    }, [base, quote]);

    const { data: tickerResp } = useTickerBatch(tickerCoins);
    const tickerMap = useMemo(() => {
        const tmpMap = new Map();
        tickerResp?.data.forEach((item) => {
            tmpMap.set(`${item.base}/${quote}`, item.price);
        });
        return tmpMap;
    }, [quote, tickerResp?.data]);

    const exchangeOrder = useMemo(() => {
        const _exchangeOrder = TradeUtils.convertToExchangeOrder({
            ...data?.order_data?.payload,
            bu_order_type: data?.bu_order_type,
            bu_order_id: data?.bu_order_id,
            bu_order_data: {
                ...data?.order_data?.payload?.bu_order_data,
                create_time: data?.order_create_time,
                close_time: data?.order_end_time,
            },
        });
        const baseTickers = tickerResp?.data?.map((item) => ({ base: item.base, quote, latest: item.price })) ?? [];

        _exchangeOrder && TradeUtils.calcGridOrderProfit(_exchangeOrder, 0, undefined, baseTickers as ExchangeTicker[]);
        return _exchangeOrder;
    }, [
        data?.bu_order_id,
        data?.bu_order_type,
        data?.order_create_time,
        data?.order_data?.payload,
        data?.order_end_time,
        quote,
        tickerResp?.data,
    ]);
    const innerOrder = exchangeOrder && (TradeUtils.getInnerOrder(exchangeOrder) as ExchangeMTGFuturesOrder | undefined);

    const { isSubmitting, gotoPionexCreateOrder } = useShareCreateOrder({ data, share_id });

    const numUtils = $num2 as any;
    const shareInfo = MTGFuturesShareProcessor.useShareCardInfo({
        exchangeOrder,
        shareData: data,
        tickerMap,
        $st: str,
        useSymbolPrice,
        $num: numUtils,
    });

    const closedTips: {
        visible: boolean;
        text?: string;
        confirmText?: string[];
    } = useMemo(() => {
        if (!exchangeOrder?.isRunning) {
            return {
                visible: true,
                text: str("grid_futures_order_closed"),
            };
        }
        if (innerOrder?.isConditionAndNotTriggered) {
            return {
                visible: true,
                text: str("share_not_triggered_tips"),
            };
        }
        return {
            visible: false,
            text: undefined,
        };
    }, [exchangeOrder?.isRunning, innerOrder?.isConditionAndNotTriggered]);

    const onCreateOrderProxy = useCallback(() => {
        if (isSubmitting) return;
        // 现货马丁暂未支持web跟单
        if (!mobileDevice) {
            modalRef.current?.show({
                title: str("confirm_follow_order_closed_title"),
                content: <div className={style.pionexModalContanerContent}>{str("confirm_follow_order_web_unsupported")}</div>,
                onConfirm() {},
                confirmText: str("modal_confirm_text"),
                cancelText: str("modal_cancel_text"),
            });
            return;
        }
        if (closedTips.visible && closedTips.confirmText) {
            modalRef.current?.show({
                title: str("confirm_follow_order_closed_title"),
                content: (
                    <div className={style.pionexModalContanerContent}>
                        {closedTips.confirmText?.map((text) => <div key={text}>{text}</div>)}
                    </div>
                ),
                onConfirm() {
                    report("mtg_futures_bot_share_follow", { share_id });
                    gotoPionexCreateOrder();
                },
                confirmText: str("confirm_follow_order_closed_title"),
            });
        } else {
            report("mtg_futures_bot_share_follow", { share_id });
            gotoPionexCreateOrder();
        }
    }, [mobileDevice, closedTips.visible, closedTips.confirmText, share_id, isSubmitting, gotoPionexCreateOrder]);

    // 当前收益率
    const currentProfitPercent = useMemo(() => {
        if (!innerOrder?.totalProfitPercent) {
            return "0";
        }

        return new SafeDecimal(innerOrder.totalProfitPercent).toDP(2, SafeDecimal.ROUND_FLOOR).valueOf();
    }, [innerOrder?.totalProfitPercent]);

    // 套利年化
    const annualizedGridProfit = useMemo(() => {
        const n = calculateAnnualized({
            createTime: exchangeOrder?.timestamp ?? 0,
            percentProfit: new SafeDecimal(innerOrder?.profitPercent ?? "0").valueOf(),
            endTime:
                exchangeOrder?.closeTimestamp && exchangeOrder?.closeTimestamp > 0 ? exchangeOrder?.closeTimestamp : new Date().getTime(),
        });
        return `${n}`.replace(/[^0-9.-]/gi, "");
    }, [exchangeOrder?.closeTimestamp, exchangeOrder?.timestamp, innerOrder?.profitPercent]);

    // 比较当前收益和年化收益
    const profitCompare = useMemo(() => {
        // 套利年化
        const annualized = {
            label: str("mtg_share_grid_profit_percent_annualized"),
            value: `${addPlus(annualizedGridProfit)}${$num2(annualizedGridProfit)}%`,
        };
        // 收益率
        const current = {
            label: str("mtg_share_profit_percent"),
            value: `${addPlus(currentProfitPercent)}${$num2(currentProfitPercent)}%`,
        };
        const annualizedBigger = new SafeDecimal(currentProfitPercent).lessThan(annualizedGridProfit);

        return {
            lager: annualizedBigger ? annualized : current,
            smaller: annualizedBigger ? current : annualized,
        };
    }, [annualizedGridProfit, currentProfitPercent]);

    const avatar = useImage(`${Constants.avatarLocationOrigin}${data?.avatar}`, `${Constants.avatarLocationOrigin}${DEFAULT_AVATAR}`);

    const subTitle = useMemo(() => {
        return <CardSubTags tags={shareInfo.subTitle} />;
    }, [shareInfo.subTitle]);
    // 用户卡片展示信息
    const userInfo = useMemo(() => {
        return {
            avatar,
            title: shareInfo.title,
            subTitle,
            info: [shareInfo.marketInfo.openBasePrice, shareInfo.marketInfo.currentBasePrice],
        };
    }, [avatar, shareInfo.marketInfo.currentBasePrice, shareInfo.marketInfo.openBasePrice, shareInfo.title, subTitle]);

    // 收益率卡片展示信息
    const profitCardInfo = useMemo(() => {
        return {
            title: profitCompare.lager.label,
            profit: profitCompare.lager.value,
            info: [
                {
                    label: profitCompare.smaller.label,
                    value: profitCompare.smaller.value,
                },
                {
                    label: str("mtg_share_running_time"),
                    value: innerOrder?.isConditionAndNotTriggered
                        ? "0"
                        : getLastDesc(
                              innerOrder?.triggerTime && innerOrder?.triggerTime > 0 ? innerOrder?.triggerTime : exchangeOrder?.timestamp,
                              exchangeOrder?.closeTimestamp && exchangeOrder?.closeTimestamp > 0
                                  ? exchangeOrder?.closeTimestamp
                                  : undefined,
                          ),
                },
                {
                    label: shareInfo.totalRoundCount.label,
                    value: `${shareInfo.totalRoundCount.value}`,
                },
                {
                    label: shareInfo.orderCreateTime.label,
                    value: !shareInfo.orderCreateTime.timeStamp
                        ? "--"
                        : moment(shareInfo.orderCreateTime.timeStamp).format("YYYY/MM/DD HH:mm"),
                },
            ],
        };
    }, [
        exchangeOrder?.closeTimestamp,
        exchangeOrder?.timestamp,
        innerOrder?.isConditionAndNotTriggered,
        innerOrder?.triggerTime,
        profitCompare.lager.label,
        profitCompare.lager.value,
        profitCompare.smaller.label,
        profitCompare.smaller.value,
        shareInfo.orderCreateTime.label,
        shareInfo.orderCreateTime.timeStamp,
        shareInfo.totalRoundCount.label,
        shareInfo.totalRoundCount.value,
    ]);

    // 底部邀请码展示信息

    return (
        <div className={!mobileDevice ? style.normalContent : style.mobileContent}>
            <ShareLandingPage
                data={data}
                topTips={closedTips}
                onCreateOrder={onCreateOrderProxy}
                title={shareInfo.title}
                subTitle={subTitle}
                userCardItems={userInfo.info}
                profitCardInfo={profitCardInfo}
                isCreateBtnLoading={isSubmitting}
            />
            <ModalConfirm ref={(_ref) => (modalRef.current = _ref)} />
        </div>
    );
};
