import { useParams } from "react-router-dom";
import style from "./style.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { $num, addPlus, gotoPionexApp, isMobile } from "src/utils";
import Constants from "src/Constants";
import { DEFAULT_AVATAR } from "src/components/ShareUserInfo";
import str from "src/i18n/useStr";
import { useTickerIndex } from "../FutureGrid/CurrentPrice/useTickerIndex";
import { SafeDecimal } from "trade_utils_lib";
import moment from "moment";
import { Wrapper } from "src/components/StyleComponent";
import { Modal, Spin } from "antd";
// eslint-disable-next-line import/namespace
import { LoadingOutlined } from "@ant-design/icons";
import UserCard from "src/ShareUIComponentV2/UserCard";
import { ProfitCard } from "src/ShareUIComponentV2/ProfitCard";
import CodeCard from "src/ShareUIComponentV2/CodeCard";
import { useSymbol } from "trade_quotation_lib";
import { CustomOrderSide, SimpleFutures, SimpleFuturesOrderResp, SimpleFuturesUtils } from "trade_futures_lib";
import { IShareData, useShareRatioData } from "./useShareRatioData";
import { useImage } from "src/use";

const SFView = ({ data, shareData, shareId }: { data: SimpleFutures.SimpleFuturesOrder; shareData: IShareData; shareId: string }) => {
    const {
        base,
        quote,
        price,
        mode,
        status,
        side,
        leverage,
        active_position_avg_price,
        history_position_avg_price,
        quote_invest,
        reason_by,
    } = data;
    const { extra_data, share_code, nick_name, avatar } = shareData;
    const mobileDevice = isMobile();
    const symbolInfo = useSymbol(base, quote);
    const { data: currentPriceResp } = useTickerIndex({
        base,
        quote,
    });
    // 头像
    const avatarImg = useImage(`${Constants.avatarLocationOrigin}${avatar}`, `${Constants.avatarLocationOrigin}${DEFAULT_AVATAR}`);
    const [submitting, setSubmitting] = useState(false);

    const baseDisplay = symbolInfo?.baseDisplay ?? "";
    const quoteDisplay = symbolInfo?.quoteDisplay ?? "";
    // 已成交
    const isRunning = status === "open";
    // 已撤单
    const isCanceled = reason_by === "CANCEL_TRIGGER" || reason_by === "CANCEL_LIMIT";
    // 已平仓
    const isClosed = !!data.close_position_avg_price;

    const isDisabled = isClosed || isCanceled;

    // 当前价格
    const currentPrice = currentPriceResp?.data?.[0]?.price ?? "";
    // 邀请码
    const shareCode = extra_data?.share_code || share_code;
    // 仓位持仓均价，如果已平仓取历史仓位的开仓均价
    const avgPrice = isRunning ? active_position_avg_price : history_position_avg_price;
    // 二维码url
    const qrUrl = `${Constants.shareHost}${window.location.pathname}`;

    const isMarket = mode === SimpleFutures.OrderMode.Market;
    const isLimit = mode === SimpleFutures.OrderMode.Limit;
    const isTrigger = mode === SimpleFutures.OrderMode.Trigger;
    const isWaitingLimit = data.is_waiting_limit;
    const isPartialFilled = data.limit_order_status === "PartialFilled";
    const isWaitingTrigger = data.is_waiting_trigger;
    const isWaiting = isWaitingLimit || isWaitingTrigger;

    // 订单类型
    const modeText = useMemo(() => {
        if (isTrigger) {
            let sub = "";
            if (isWaitingTrigger) {
                sub = str("sf_trigger_waiting");
            } else {
                sub = str("sf_trigger_triggered");
            }
            return `${str("sf_order_mode_trigger")} (${sub})`;
        }
        if (isLimit) {
            let sub = "";
            if (isWaitingLimit) {
                sub = str("sf_limit_waiting");
            } else if (isPartialFilled) {
                sub = str("sf_limit_partial_filled");
            } else {
                sub = str("sf_limit_filled");
            }
            return `${str("sf_order_mode_limit")} (${sub})`;
        }
        return str("sf_order_mode_market");
    }, [isLimit, isPartialFilled, isTrigger, isWaitingLimit, isWaitingTrigger]);

    // 用户卡片展示信息
    const userInfo = (() => {
        const trendText = side === CustomOrderSide.BUY ? str("trade_trend_long") : str("trade_trend_short");
        const leverageText = leverage + "x";
        const symbolDisplay = `${baseDisplay} / ${quoteDisplay}`;

        return {
            avatar: avatarImg,
            title: str("sf_title", { name: nick_name ?? "" }),
            subTitle: (
                <>
                    <div>{symbolDisplay}</div>
                    <div className={style.infoLine}></div>
                    <div>{leverageText}</div>
                    <div className={style.infoLine}></div>
                    <div>{trendText}</div>
                </>
            ),
            info: [
                ...(!isMarket
                    ? [
                          {
                              label: str("trade_order_type"),
                              value: modeText,
                          },
                      ]
                    : []),
                ...(isWaitingLimit
                    ? [
                          {
                              label: str("placed_price_key"),
                              value: `${price ? $num(price, symbolInfo?.precision ?? 2) : "--"} ${quoteDisplay}`,
                          },
                      ]
                    : []),
                ...(isWaitingTrigger
                    ? [
                          {
                              label: str("trade_trigger_price"),
                              value: `${price ? $num(price, symbolInfo?.precision ?? 2) : "--"} ${quoteDisplay}`,
                          },
                      ]
                    : []),
                ...(!isWaiting
                    ? [
                          {
                              label: str("position_avg_price"),
                              value: `${avgPrice ? $num(avgPrice, symbolInfo?.precision ?? 2) : "--"} ${quoteDisplay}`,
                          },
                      ]
                    : []),
                {
                    label: str("future_current_price"),
                    value: `${currentPrice ? $num(currentPrice, symbolInfo?.precision ?? 2) : "--"} ${quoteDisplay}`,
                },
            ],
        };
    })();

    // 总利润、总利润率
    const totalProfitRate = (() => {
        // 利润计算所使用的当前价格，如果订单已经平仓，则不需要
        let profitPrice;
        if (isRunning) {
            if (currentPrice) {
                profitPrice = currentPrice;
            } else {
                return;
            }
        }
        const totalProfit = SimpleFuturesUtils.getTotalProfit(data, profitPrice);
        const invest = new SafeDecimal(quote_invest);
        if (invest.eq(0)) {
            return;
        }
        return new SafeDecimal(totalProfit).div(invest).times(100).toFixed();
    })();

    // 收益率卡片展示信息
    const profitInfo = (() => {
        let status;
        if (isCanceled) {
            status = str("sf_order_canceled");
        }
        if (isWaitingTrigger) {
            status = str("sf_trigger_waiting");
        }
        if (isWaitingLimit) {
            status = str("sf_limit_waiting");
        }
        return {
            title: !!status ? str("current_state") : str("futures_grid_v2_total_profit"),
            status,
            profit: totalProfitRate ? `${addPlus(totalProfitRate)}${$num(totalProfitRate, 2)}%` : "--",
            info: [
                {
                    label: !!status ? str("sf_trigger_create_time") : str("futures_grid_v2_order_create_time"),
                    value: moment(data?.create_time).format("YYYY/MM/DD HH:mm"),
                },
            ],
        };
    })();

    // 底部邀请码展示信息
    const codeInfo = useMemo(() => {
        return {
            title: str("futures_grid_v2_share_code", { name: nick_name }),
            code: shareCode,
            followUrl: qrUrl,
        };
    }, [nick_name, qrUrl, shareCode]);

    // 跳转去跟单
    const onCreate = useCallback(() => {
        setSubmitting(true);
        setTimeout(() => setSubmitting(false), 3000);
        const lang = new URLSearchParams(window.location.search).get("l") || "zh-TW";
        const mUrl = `https://download.pionex.com/?lang=${lang}`;
        gotoPionexApp({
            url: mUrl,
            mUrl,
            page: "TRADE_MANUAL.futures_perp",
            shareId: shareId,
            referral: shareCode,
        });
    }, [shareCode, shareId]);

    // 点击跟单
    const onClick = useCallback(() => {
        if (submitting) {
            return;
        }

        if ((isMarket && isRunning) || (!isMarket && isWaiting && !isCanceled)) {
            onCreate();
            return;
        }
        let modalTitle = "";
        let modalContent = "";
        if (isClosed) {
            // 已平仓
            modalTitle = str("position_status_close");
            modalContent = str("position_close_detail");
        }
        if (isRunning) {
            // 已成交
            modalTitle = str("order_open_tip_title");
            modalContent = str("order_open_tip_content");
        }
        if (isCanceled) {
            // 已撤单
            modalTitle = str("order_canceled_tip_title");
            modalContent = str("order_canceled_tip_content");
        }

        Modal.confirm({
            centered: true,
            title: modalTitle,
            content: modalContent,
            onOk: onCreate,
            cancelText: str("button_cancel"),
            okText: str("subscription_success_confirm"),
            okButtonProps: {
                style: { backgroundColor: "#ff7028", borderColor: "#ff7028" },
            },
            cancelButtonProps: { className: "cancleBtn" },
        });
    }, [submitting, isMarket, isRunning, isWaiting, isClosed, isCanceled, onCreate]);

    return (
        <div className={!mobileDevice ? style.normalContent : style.mobileContent}>
            <div className={style.logoBox}>
                <img className={style.logoIcon} src={require("../../images/logo_white.png")} alt="" />
            </div>
            {isDisabled && (
                <div className={style.orderClosedTipsBox}>
                    <img className={style.orderClosedTipsIcon} src={require("../../images/tips.png")} alt="" />
                    <div className={style.orderClosedTipsText}>
                        {isCanceled ? str("position_status_canceled") : str("position_status_close")}
                    </div>
                </div>
            )}
            <UserCard data={userInfo} />
            <ProfitCard data={profitInfo} />
            <div
                className={`${style.createOrderBtnBase} ${!isDisabled ? style.createOrderBtn : style.createOrderBtnDisable}`}
                onClick={onClick}
            >
                {submitting && <img alt="" src={require("../../images/icon_loading_white.png")} className={style.loadingIcon} />}
                {str("futures_grid_v2_create_order")}
            </div>
            <CodeCard data={codeInfo} />
        </div>
    );
};

export const SF = () => {
    const { share_id } = useParams<{ share_id: string }>();
    const { data, isLoading } = useShareRatioData({ id: share_id });
    const orderData = useMemo(() => {
        if (!data?.order_data?.payload) {
            return null;
        }
        return SimpleFuturesUtils.transferOrderAPIToUI(data.order_data.payload as unknown as SimpleFuturesOrderResp);
    }, [data]);
    const mobileDevice = isMobile();

    useEffect(() => {
        document.body.classList.add(style.page);
        document.documentElement.classList.add("theme-dark");
        return () => {
            document.body.classList.remove(style.page);
            document.documentElement.classList.remove("theme-dark");
        };
    }, []);

    if (isLoading || !data || !orderData) {
        return (
            <Wrapper style={{ marginTop: mobileDevice ? "50%" : "10%" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />} />
            </Wrapper>
        );
    }
    return <SFView data={orderData} shareData={data} shareId={share_id} />;
};
